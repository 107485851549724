import { NgModule } from '@angular/core';

import { LoginModule } from 'app/main/pages/authentication/login/login.module';
// import { Login2Module } from 'app/main/pages/authentication/login-2/login-2.module';
// import { RegisterModule } from 'app/main/pages/authentication/register/register.module';
// import { Register2Module } from 'app/main/pages/authentication/register-2/register-2.module';
// import { ForgotPasswordModule } from 'app/main/pages/authentication/forgot-password/forgot-password.module';
// import { ForgotPassword2Module } from 'app/main/pages/authentication/forgot-password-2/forgot-password-2.module';
// import { ResetPasswordModule } from 'app/main/pages/authentication/reset-password/reset-password.module';
// import { ResetPassword2Module } from 'app/main/pages/authentication/reset-password-2/reset-password-2.module';
// import { LockModule } from 'app/main/pages/authentication/lock/lock.module';
// import { MailConfirmModule } from 'app/main/pages/authentication/mail-confirm/mail-confirm.module';
// import { ComingSoonModule } from 'app/main/pages/coming-soon/coming-soon.module';
// import { Error404Module } from 'app/main/pages/errors/404/error-404.module';
// import { Error500Module } from 'app/main/pages/errors/500/error-500.module';
// import { InvoiceModernModule } from 'app/main/pages/invoices/modern/modern.module';
// import { InvoiceCompactModule } from 'app/main/pages/invoices/compact/compact.module';
import { MaintenanceModule } from 'app/main/pages/maintenance/maintenence.module';
import { FuseConfirmDialogModule, FuseDemoModule, FuseProgressBarModule } from '../../../@fuse/components';
import { NotificationsModule } from './notifications/notifications.module';
import { RouterModule } from '@angular/router';
import { SchedularStatusComponent } from './scheduler-status/schedular-status.component';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { BettingConfigComponent } from './betting-config/betting-config.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { BetradarImportComponent } from './betradar-import/betradar-import.component';
import { MappingFormDialogComponent } from './betradar-import/mapping-form/mapping-form.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { SearchBetComponent } from './search-bet/search-bet.component';
import { CartComponent } from './search-bet/cart/cart.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PaginationDirective } from './search-bet/pagination.directive';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { CustomEventsComponent } from './custom-events/custom-events.component';
import { CrexScoreComponent } from './crex-score/crex-score.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomCompetitionComponent } from './custom-competition/custom-competition.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TwoFactorComponent } from 'app/google2fa/TwoFactor.component';
import { Google2faModule } from 'app/google2fa/google2fa.module';


// import { PricingModule } from 'app/main/pages/pricing/pricing.module';
// import { ProfileModule } from 'app/main/pages/profile/profile.module';
// import { SearchClassicModule } from 'app/main/pages/search/classic/search-classic.module';
// import { SearchModernModule } from 'app/main/pages/search/modern/search-modern.module';
// import { FaqModule } from 'app/main/pages/faq/faq.module';
// import { KnowledgeBaseModule } from 'app/main/pages/knowledge-base/knowledge-base.module';

const routes = [
    {
        path: 'schedular-status',
        component: SchedularStatusComponent
    },
    {
        path: 'betting-config',
        component: BettingConfigComponent,
    },
    {
        path: 'betradar-import',
        component: BetradarImportComponent
    },
    {
        path: 'search-bet',
        component: SearchBetComponent
    },
    {
        path: 'cart',
        component: CartComponent
    },
    {
        path: 'custom-events',
        component: CustomEventsComponent
    },
    {
        path: 'crex-score',
        component: CrexScoreComponent
    },
    {
        path: 'custom-competitions',
        component: CustomCompetitionComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        // Authentication
        LoginModule,
        // Login2Module,
        // RegisterModule,
        // Register2Module,
        // ForgotPasswordModule,
        // ForgotPassword2Module,
        // ResetPasswordModule,
        // ResetPassword2Module,
        // LockModule,
        // MailConfirmModule,
        //
        // // Coming-soon
        // ComingSoonModule,
        //
        // // Errors
        // Error404Module,
        // Error500Module,
        //
        // // Invoices
        // InvoiceModernModule,
        // InvoiceCompactModule,
        //
        // Maintenance
        Google2faModule,
        MaintenanceModule,
        FuseDemoModule,
        NotificationsModule,
        FuseSharedModule,
        FuseDemoModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatListModule,
        MatToolbarModule,
        MatIconModule,
        MatDialogModule,
        FuseSharedModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,
        MatCheckboxModule,
        MatTableModule,
        FuseConfirmDialogModule,
        FuseProgressBarModule,
        NgxMatSelectSearchModule,
        MatTabsModule
        //
        // // Pricing
        // PricingModule,
        //
        // // Profile
        // ProfileModule,
        //
        // // Search
        // SearchClassicModule,
        // SearchModernModule,
        //
        // // Faq
        // FaqModule,
        //
        // // Knowledge base
        // KnowledgeBaseModule
    ],
    declarations: [SchedularStatusComponent, BettingConfigComponent, BetradarImportComponent, MappingFormDialogComponent, CartComponent, SearchBetComponent, PaginationDirective, CustomEventsComponent, CrexScoreComponent, CustomCompetitionComponent],
    entryComponents: [
        MappingFormDialogComponent,
        FuseConfirmDialogComponent
    ]
})
export class PagesModule {

}
