import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatDialog } from '@angular/material';
import { Google2FAComponent } from 'app/google2fa/google2fa.component';
import { Router } from '@angular/router';

export interface EnableG2FA {
    status: Status
    success: boolean
    result: EnableG2FAResult
}
export interface Status {
    statusCode: string
    statusDesc: string
}

export interface EnableG2FAResult {
    QRCode: string
    setUpCode: string
    referenceGUID: string
}

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;
    enableG2FA: boolean = false

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(private dialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _http: HttpClient,
        private authorizationService: AuthenticationService,
        private router: Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    enableDisableG2FA() {
        // https://admin.diamondexchange09.com/api/agency/agent/GoogleAuthenticator/Enable
        // this.enableG2FA = !this.enableG2FA
        const URL = environment.host + '/api/agency/GoogleAuthenticator/Enable';
        this._http.get(URL, {
            headers: this.authorizationService.getHeaders()
        }).subscribe((res: EnableG2FA) => {
            this.openGoogle2FAModal(res);
        });
    }
    openGoogle2FAModal(data: EnableG2FA): void {
        const dialogRef = this.dialog.open(Google2FAComponent, {
            width: '500px',
            data // You can pass data to the modal if needed
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            // Handle any actions after the dialog is closed
        });
    }

    goToSettledMarkets() {
        this.router.navigate(['/settled-markets']);
    }
}
