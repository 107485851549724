import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { navigation } from '../navigation/navigation';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    host = environment.host;
    // host = 'http://127.0.0.1:5004';
    // host = 'https://bo.lotusbook247.io';
    //  host = '';
    private currentUserSubject: BehaviorSubject<UserModel>;
    public currentUser: Observable<UserModel>;

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    public checkToken = (chk) => {
        if (chk == null) {
            this.router.navigate(['/pages/auth/login']);
            return;
        } else {
            return chk;
        }
    }

    public getHeaders = () => {
        // @ts-ignore
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.checkToken(localStorage.getItem('token'))
        });
    }

    login = (mobile: string, password: string, googleOTP?: string) => {
        return this.http.post<any>(this.host + '/api/appusers/auth/login', {
            mobile,
            password,
            googleOTP
        }, { observe: 'response' as 'body' })
            .pipe(map((response: HttpResponse<any>) => {
                if (response.body.status.statusCode === '0' && response.body.success) {
                    localStorage.setItem('currentUser', JSON.stringify(response.body.result));
                    localStorage.setItem('token', response.headers.get('authorization'));
                    this.currentUserSubject.next(response.body.result);
                    this.getNavigation();
                }
                return response.body;
            })

                //     , catchError(err => {
                //     console.log(err);
                //     this.logout();
                //     return of(err);
                // })

            );
    }

    getNavigation = () => {

        const userObject = this.currentUserValue;

        if (userObject == null) {
            return navigation;
        }

        const nav = navigation.map(a => {
            a.children = a.children.filter(b => {
                return userObject.permissions.indexOf(b.id) !== -1;
            });
            return a;
        });
        return nav;

    }

    logout(): void {
        // Clear user data from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        localStorage.clear();
        this.router.navigate(['/pages/auth/login']);
        // You may want to perform other logout-related tasks here
    }
}
