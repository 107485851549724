import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Google2FAComponent } from 'app/google2fa/google2fa.component';


@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private dialog: MatDialog) { }

    openGoogle2FAModal() {
        return this.dialog.open(Google2FAComponent, {
            width: '400px',
            data: {} // You can pass data to the modal if needed
        });
    }
}