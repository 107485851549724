import { NgModule } from "@angular/core";
import { Google2FAComponent } from './google2fa.component';
import { MatDialogModule, MatButtonModule, MatIconModule, MatTableModule, MatPaginatorModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TwoFactorComponent } from './TwoFactor.component';
import { SettledMarketsComponent } from 'app/settled-markets/SettledMarkets.component';
import { SettledListComponent } from 'app/settled-markets/settled-list/settled-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
    exports: [
        Google2FAComponent,
        TwoFactorComponent,
        SettledMarketsComponent,
        SettledListComponent
    ],
    declarations: [
        Google2FAComponent,
        TwoFactorComponent,
        SettledMarketsComponent,
        SettledListComponent
    ],
    entryComponents: [
        Google2FAComponent,
        TwoFactorComponent,
        SettledMarketsComponent,
        SettledListComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule
    ],
})
export class Google2faModule { }