import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EcommerceProductService } from 'app/main/apps/e-commerce/product/product.service';
import { MatTableModule } from '@angular/material/table';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-crex-score',
    templateUrl: './crex-score.component.html',
    styleUrls: ['./crex-score.component.scss'],
})
export class CrexScoreComponent implements OnInit {

    subs: any[] = [];
    displayedColumns: string[] = ['eventType', 'eventId', 'feedId', 'action'];
    constructor(private _httpClient: HttpClient) { }

    ngOnInit() {
        this.getCrexEventFeedMap();
    }

    getCrexEventFeedMap() {
        this.crexGetSubscribedEventFeedMap().then((res: any) => {
            console.log(res);
            this.subs = res.result;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    public checkToken = (chk) => {
        if (chk == null) {
            return;
        } else {
            return chk;
        }
    }

    public getHeaders = () => {
        // @ts-ignore
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.checkToken(localStorage.getItem('token'))
        });
    }

    crexGetSubscribedEventFeedMap() {
        // https://scoreboard.prod.gemexch.co/api/Match/GetSubscribedEventFeedMap
        return new Promise<any>(((resolve, reject) => {
            this._httpClient.get(`${environment.scoreboardUrl}/api/Match/GetSubscribedEventFeedMap`, {
                headers: this.getHeaders()
            }).subscribe((response) => {
                resolve(response);
            }, reject);
        }));
    }



    unsub(feedId: any) {
        if (confirm("Are you sure you want to unsubscribe?")) {
            this._httpClient.post(`${environment.scoreboardUrl}/api/Match/UnSubscribeFeed?feed=` + feedId, {}, {
                headers: this.getHeaders()
            }).subscribe((response) => {
                console.trace(response, "unsubscribed");
                this.getCrexEventFeedMap();
            }, err => {
                console.log(err);
            });
        }
    }





}
