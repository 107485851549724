import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const isLoginPage = state.url === '/pages/auth/login';
    const isTwoFactorPage = state.url === '/pages/2fa';
    if (currentUser) {
      if (currentUser.isGAEnabled) {
        // User is logged in and 2FA is enabled, allow access to all routes
        return true;
      } else if (!isTwoFactorPage) {
        // Redirect to 2FA page if not already on it
        return this.router.createUrlTree(['2fa']);
      }
    } else if (isLoginPage) {
      return true;
    } else {
      // User is not logged in, redirect to login page
      return this.router.createUrlTree(['/pages/auth/login']);
    }

    // Allow access if already on the 2FA page
    return true;
  }
}
