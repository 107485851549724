import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import { NotificationsComponent } from './notifications.component';
import {FuseDemoModule} from "../../../../@fuse/components";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";

const routes = [
    {
        path     : 'notifications',
        component: NotificationsComponent
    }
];

@NgModule({
  declarations: [NotificationsComponent],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        FuseDemoModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule
    ]
})
export class NotificationsModule { }
