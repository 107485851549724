import {
    MatRadioChange,
    MatTableDataSource
} from '@angular/material';
import {api} from '../../../models/api';
import _date = moment.unitOfTime._date;
import {Component, OnInit} from '@angular/core';
import {SearchService} from '../../../services/search.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {getTime, setHours, setMinutes} from 'date-fns';
import * as moment from 'moment';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';

// tslint:disable-next-line:class-name
class markets {

    views: string;
    value: string;
}

// tslint:disable-next-line:class-name
class type {
    bet: string;
    value: string;
}

// tslint:disable-next-line:class-name
class sport {

    view: string;
    value: number;
}

// @ts-ignore
@Component({
    selector: 'app-search-bet',
    templateUrl: './search-bet.component.html',
    styleUrls: ['./search-bet.component.scss'],
    providers: []
})
export class SearchBetComponent implements OnInit {

    // tslint:disable-next-line:typedef
    private currentPage: any;
    filters: FormGroup;
    pageNo = 1;
    totalPages = 1;
    selectedItems: any[];


    constructor(private service: SearchService,
                public _formBuilder: FormBuilder,
                private _matSnackBar: MatSnackBar,
                private fuseProgressBar: FuseProgressBarService) {
        // this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
        this.fuseProgressBar.setMode('indeterminate');
        this.filters = this._formBuilder.group({

            startDate: [new Date(), Validators.required],
            endDate: [new Date(), Validators.required],
            startTime: ['00:00', Validators.required],
            endTime: ['11:59', Validators.required],
            marketId: [''],
            sport: [''],
            markets: [''],
            event: [''],
            member_name: [''],
            fromOdds: [''],
            toOdds: [''],
            fromStakes: [''],
            toStakes: [''],
            type: ['settled'],
            pageNo: ['1'],

        });

    }


    public Odds = false;
    public buttonName: any = 'Odds:All';
    public Stake = false;
    public Name: any = 'Stake:All';
    public button: any = 'current';
    public name: any = 'past';

    public matched = false;
    mainBetType = 'settled';

    selectedValue = -1;
    sports: sport[] = [
        {view: 'cricket', value: 4},
        {view: 'football', value: 1},
        {view: 'tennis', value: 2},
        {view: 'Horse racing', value: 7},
        {view: 'Gray hound racing', value: 4339},
        {view: 'XG', value: 1444001}
    ];
    selectValue = 'ALL';
    markets: markets[] = [
        {views: 'Match odds', value: 'MATCH_ODDS'},
        {views: 'Bookmaker', value: 'MATCH_ODDS_SB'},
        {views: 'session Runs ex', value: 'INNINGS_RUNS'},
        {views: 'Tied Match', value: 'TIED_MATCH'},
        {views: 'Completed Matched', value: 'COMPLETED_MATCHED'},
        {views: 'win', value: 'WIN'},
        {views: 'To Qualify', value: 'TO_QUALIFY'},
        {views: 'Over/under', value: 'OVER/UNDER'},
        {views: 'Tennis Fancy', value: 'TENNIS_FANCY'},
        {views: 'Super Over', value: 'SUPER_OVER'},
        {views: 'Outright', value: 'OUTRIGHT'},
        {views: 'other', value: 'OTHER'}

    ];
    selectedType = 'matched';
    types = [{bet: 'matched', name: 'matched'}, {bet: 'unmatched', name: 'unmatched'}];

    selection = new SelectionModel<api>(true, []);

    Types = [{search: 'settled', name: 'past'}];
    ELEMENT_DATA: api[];
    dataSource = new MatTableDataSource<api>(this.ELEMENT_DATA);

    displayedColumns: string[] = ['select', 'loginName', 'betPlacedDate', 'BetId', 'inPlay', 'eventName', 'marketId',
        'competitionName', 'marketName', 'selectionName', 'Odds', 'stake', ' memberWin ', 'sportName'];

    picker: _date;


    // onclick = () => {
    //    return  this.filters.get('type');
    //    this.submit();
    // }
    // tslint:disable-next-line:typedef
    isAllSelected() {
        // const numSelected = this.selection.dataSource.data;
        // const numRows = this.dataSource.data.length;
        // return numSelected;
    }

    // tslint:disable-next-line:typedef
    masterToggle($event) {
        console.log($event);
        this.dataSource.data.forEach(row => {
            row.selected = $event.checked;
        });

        this.dataSource.data.forEach(row => {
            console.log(row.selected);
        });


        //  this.isAllSelected() ?
        //      this.selection.clear() :
        //      this.dataSource.data.forEach(row => this.selection.select(row));
        //
        // console.log(this.selection);


    }

    // tslint:disable-next-line:typedef

    // checkboxLabel(row?: api): string {
    //     // if (!row) {
    //     //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    //     }
    //     return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.loginName + 1}`;
    //
    // }

    // fetchSelectedItems() {
    //     this.selectedItemsList = this.ELEMENT_DATA.filter((value, index) => {
    //         return value;
    //     });
    // }

    toggle = () => {

        this.Odds = !this.Odds && !this.Stake;
    }

    toggles = () => {

        this.Stake = !this.Stake && !this.Odds;
    }

    report = () => {
        this.mainBetType = 'current';
        this.matched = !this.matched;

    }

    Datashow = () => {


        // this.selectedItems = this.ELEMENT_DATA.filter((value, index) => {
        //     return value;
        // });
    }
    // this.selectedItems.push(this.selection);
    // console.log(this.selectedItems);    }

    // selected = () => {
    //     this.selectedItems.push(this.selection);
    //     console.log(this.selectedItems);
    // }

    ngOnInit(): void {
        // this.selectedItems = new Array<string>();
        // this.Indexing()
    }

    public getAllReports = (formOutput: any, ) => {

        formOutput.startDate = setHours(formOutput.startDate, formOutput.startTime.split(':')[0]);
        formOutput.startDate = setMinutes(formOutput.startDate, formOutput.startTime.split(':')[1]);
        formOutput.startDate = getTime(formOutput.startDate);

        formOutput.endDate = setHours(formOutput.endDate, formOutput.endTime.split(':')[0]);
        formOutput.endDate = setMinutes(formOutput.endDate, formOutput.endTime.split(':')[1]);
        formOutput.endDate = getTime(formOutput.endDate);

        if (this.mainBetType !== 'current') {
            formOutput.type = this.mainBetType;
        }

        this.fuseProgressBar.show();
        this.service.searchBet(this.pageNo, formOutput.marketId, formOutput.sport,
            formOutput.markets, formOutput.startDate, formOutput.endDate, formOutput.startTime,
            formOutput.endTime, formOutput.type, formOutput.member_name).then((report: any) => {
            this.fuseProgressBar.hide();
            console.log(report);
            this.dataSource.data = report.result.pageRows as api[];
            this.totalPages = report.result.lastPage;
            this.currentPage = report.result.currentPage;
        });

    }

    // tslint:disable-next-line:typedef
    submit() {

        const formOutput: any = this.filters.getRawValue();
        this.getAllReports(formOutput);

    }

    // tslint:disable-next-line:typedef
    onPageChange(e) {
        this.pageNo = e;
        this.submit();
    }

    reports = () => {
        this.mainBetType = 'settled';
    }

    // tslint:disable-next-line:typedef
    radioChangeHandler($event: Event) {

    }

    onSelectedBetType = ($event: MatRadioChange) => {
        this.submit();
    }

    betCheckBoxClicked = (checkEvent, bet) => {
        console.log(checkEvent);
        this.dataSource.data.forEach(a => a.apolloBetId === bet.apolloBetId ? a.selected = checkEvent.checked : null);
    }

    getSelectionCount = (data): number => {
        if (data.data !== null && data.data !== undefined) {
            return data.data.filter(a => a.selected).length;
        } else {
            return 0;
        }
    }

    markBets(_type: string): void {

        const betsToBeMarked = this.dataSource && this.dataSource.data && this.dataSource.data.filter(a => a.selected).map(a => a.apolloBetId);

        console.log(betsToBeMarked);

        let rejectedMessage;

        if (betsToBeMarked) {
            this.service.markForInvalidRevalid(_type, betsToBeMarked).then((res: any) => {

                this._matSnackBar.open(res.result.message, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });

                rejectedMessage = res.result.rejected;

            });
        } else {
            this._matSnackBar.open('No bets were marked for invalid', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
        }
    }


}
