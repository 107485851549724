import {Component, OnDestroy, OnInit} from '@angular/core';
import {IntranetService} from '../../../services/intranet.service';
import {BehaviorSubject} from 'rxjs';
import {UtilsService} from '../../../services/utils.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PendingImport} from './pending.import';
import {FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MappingFormDialogComponent} from './mapping-form/mapping-form.component';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {EventDetails} from './mapping-form/event.details';

@Component({
    selector: 'app-betradar-import',
    templateUrl: './betradar-import.component.html',
    styleUrls: ['./betradar-import.component.scss']
})
export class BetradarImportComponent implements OnInit, OnDestroy {

    widget: any;
    pendingBetfairEvents: PendingImport[];
    onBetradarChanged: BehaviorSubject<any>;
    onBetfairChanged: BehaviorSubject<any>;
    pendingBetRadarEvents: PendingImport[];
    pollers;
    eventTypes = [{title: 'Cricket', eventType: 4},
        {title: 'Soccer', eventType: 1},
        {title: 'Tennis', eventType: 2}];
    eventTypeId: any;
    timeout: any;

    pendingImports: PendingImport;
    private dialogRef: any;
    private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(
        private intranetService: IntranetService,
        private utilsService: UtilsService,
        private matSnackBar: MatSnackBar,
        private _matDialog: MatDialog
    ) {
        this.onBetradarChanged = new BehaviorSubject<any>({});
        this.onBetfairChanged = new BehaviorSubject<any>({});
    }

    polling = false;

    ngOnInit(): void {
        this.widget = [];
        this.startPolling();
    }

    public startPolling(): void {

        if (this.polling){
            return;
        }

        this.polling = true;

        this.getUpdate(true);


        // if (this.pollers) {
        //     clearInterval(this.pollers);
        // }


        // this.pollers = setInterval(() => {
        //     this.getUpdate();
        // }, 5000);
    }

    getUpdate = (repeat: boolean) => {

        this.intranetService.pendingBfImports(this.eventTypeId).then((value: any) => {
            if (value.success) {
                this.pendingBetRadarEvents = value.result.pendingBrEvents as PendingImport[];
                this.pendingBetfairEvents = value.result.pendingBfEvents as PendingImport[];
                this.onBetradarChanged.next(value.result.pendingBrEvents as PendingImport[]);
                this.onBetfairChanged.next(value.result.pendingBfEvents as PendingImport[]);
            } else {
                clearInterval(this.pollers);
                this.utilsService.parseResponseAndShowSnackBar(this.matSnackBar, value);
            }
            if (repeat){
                setTimeout(() => {
                    this.getUpdate(repeat);
                }, 5000);
            }

        });
    }

    ngOnDestroy(): void {
        clearInterval(this.pollers);
    }

    getCactusEventDetails(eventId: string): any {
        this.intranetService.getCactusEventDetails(eventId).then(value => {
            this.utilsService.parseResponseAndShowSnackBar(this.matSnackBar, value);
        });
    }

    eventMapping(bfEventId: string, standalone: boolean, brEventId: string): any {
        this.intranetService.setBrEventId(bfEventId, standalone, brEventId).then(value => {
            this.utilsService.parseResponseAndShowSnackBar(this.matSnackBar, value);
        });
    }

    openMappingForm(pendingImport: PendingImport): void {


        this.dialogRef = this._matDialog.open(MappingFormDialogComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                request: pendingImport,
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                const eventDetails = response[2] as EventDetails;
                const savedImport = formData.getRawValue() as PendingImport;
                if (actionType === 'save') {

                    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
                        disableClose: false
                    });

                    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to map ' + savedImport.name + ' <======> ' + eventDetails.event.name + ' ??';

                    this.confirmDialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.eventMapping(savedImport.bfEventId, false, savedImport.id);
                        }
                        this.confirmDialogRef = null;
                    });
                }
            });
    }

    createStandAloneBREvent(row: PendingImport): any {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to create standalone BR event??';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.eventMapping(row.id, true, row.id);
            }
            this.confirmDialogRef = null;
        });
    }
}
