import { Component, OnInit, Inject } from '@angular/core';
import { EnableG2FA } from '@fuse/components/navigation/navigation.component';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'two-factor',
    templateUrl: './TwoFactor.component.html',
    styleUrls: ['./TwoFactor.scss']
})
export class TwoFactorComponent implements OnInit {
    qrSource: string = "";
    setupCode: string = "";
    inputCode: string = "";
    isEnabled: boolean = false;
    public data: EnableG2FA

    constructor(
        private authorizationService: AuthenticationService,
        private _http: HttpClient,
        private _matSnackBar: MatSnackBar) {
    }

    ngOnInit() {
        this.enableDisableG2FA();
    }

    enableDisableG2FA() {
        const URL = environment.host + '/api/agency/GoogleAuthenticator/Enable';
        this._http.get(URL, {
            headers: this.authorizationService.getHeaders()
        }).subscribe((res: EnableG2FA) => {
            // this.openGoogle2FAModal(res);
            this.data = res;
            this.qrSource = this.data && this.data.result && this.data.result.QRCode;
            this.setupCode = this.data && this.data.result && this.data.result.setUpCode;
            this.isEnabled = this.data && this.data.success;
        });
    }

    copyToClipboard(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        this._matSnackBar.open("Copied to clipboard", 'OK', {
            verticalPosition: 'top',
            duration: 2000
        });
    }

    enable() {
        const url = environment.host + "/api/agency/GoogleAuthenticator/Enable";
        if (!this.inputCode || !this.data && this.data.result && this.data.result.referenceGUID) {
            this._matSnackBar.open("Please enter the code and try again", 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
            return;
        }
        const body = {
            inputCode: this.inputCode,
            refrenceGuid: this.data && this.data.result && this.data.result.referenceGUID
        }
        this._http.post(url, body, {
            headers: this.authorizationService.getHeaders()
        }).subscribe((res: any) => {
            // this.dialogRef.close();
            if (res && res.success) {
                this._matSnackBar.open("Enabled successfully, Kindly login again", 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
                setTimeout(() => {
                    this.authorizationService.logout();
                }, 2000);
            } else {
                this._matSnackBar.open(res && res.status.statusDesc ? res.status.statusDesc : 'Something went wrong', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        })

    }
}