import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../../../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class LoginServiceService implements Resolve<any> {

    constructor(private _httpClient: HttpClient,
                private _authorizationService: AuthenticationService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAppState()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAppState(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this._authorizationService.host + '/api/misc/app/state', {
                headers: {'Content-Type': 'application/json'}
            }).subscribe((response: any) => {
                if (!response.success) {
                    this.router.navigate(['/pages/maintenance']);
                }
                resolve(response);
            }, reject);
        });
    }
}
