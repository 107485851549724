import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FancyLogsResult } from 'app/models/fancy-logs.interface';

@Component({
    selector: 'settled-list',
    templateUrl: './settled-list.component.html',
    styleUrls: ['./settled-list.component.scss'],
    animations: fuseAnimations
})
export class SettledListComponent implements OnInit {
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['user', 'marketId', 'timestamp', 'winner', 'ip', 'userId'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @Input() listData = [] as FancyLogsResult[];

    constructor() {
        // Initialize the dataSource with the data from SettledListData
        // this.dataSource = new MatTableDataSource(this.listData);
    }

    ngOnInit(): void {
        console.log("listData", this.listData);
        this.dataSource = new MatTableDataSource(this.listData);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    editContact(market: any): void {
        // Implement if needed
        // console.log('Market clicked:', market);
    }
}