import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSharedModule} from '@fuse/shared.module';

const routes = [
    {
        path: 'e-commerce',
        loadChildren: './e-commerce/e-commerce.module#EcommerceModule'
    },
    {
        path: 'super-admins',
        loadChildren: './contacts/contacts.module#ContactsModule'
    },
    {
        path: 'open-markets',
        loadChildren: './open-markets/market.module#MarketModule'
    },
    {
        path: 'closed-markets',
        loadChildren: './closed-markets/closed-market.module#ClosedMarketModule'
    },
    {
        path: 'bets',
        loadChildren: './bets/bets.module#BetsModule'
    },
    {
        path        : 'chat',
        loadChildren: './chat/chat.module#ChatModule'
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule
    ]
})
export class AppsModule {
}
