import {Component, OnDestroy, OnInit} from '@angular/core';
import {IntranetService} from '../../../services/intranet.service';
import {BehaviorSubject} from 'rxjs';
import {UtilsService} from '../../../services/utils.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BettingConfig} from './betting.config';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MarutiConfig} from './maruti.config';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-betting-config',
    templateUrl: './betting-config.component.html',
    styleUrls: ['./betting-config.component.scss']
})
export class BettingConfigComponent implements OnInit, OnDestroy {

    widget: any;
    onWidgetChanged: BehaviorSubject<any>;
    pollers;
    bettingConfig: BettingConfig;
    marutiConfig: MarutiConfig;
    marutiConfigForm: FormGroup;

    constructor(
        private intranetService: IntranetService,
        private utilsService: UtilsService,
        private matSnackBar: MatSnackBar,
        private formBuilder: FormBuilder
    ) {
        this.onWidgetChanged = new BehaviorSubject<any>({});
        this.bettingConfig = new BettingConfig();
        this.marutiConfig = new MarutiConfig();
        this.marutiConfigForm = this.createMarutiForm();
    }

    private createMarutiForm(): FormGroup {
        return this.formBuilder.group({
            INR: [this.marutiConfig.Maruti_Dabba_Threshold.INR, [Validators.required]],
            PKR: [this.marutiConfig.Maruti_Dabba_Threshold.PKR, [Validators.required]],
            HKD: [this.marutiConfig.Maruti_Dabba_Threshold.HKD, [Validators.required]],
            USD: [this.marutiConfig.Maruti_Dabba_Threshold.USD, [Validators.required]],
            GBP: [this.marutiConfig.Maruti_Dabba_Threshold.GBP, [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.widget = [];
        this.getBettingConfig();
        this.getMarutiConfig();
    }

    private getBettingConfig(): any {
        this.intranetService.getBettingConfig().then((val) => {
            this.bettingConfig = new BettingConfig(val);
        });
    }

    private getMarutiConfig(): any {
        this.intranetService.getMarutiConfig().then((val) => {
            this.marutiConfig = new MarutiConfig(val);
            this.marutiConfigForm = this.createMarutiForm();
            // this.onWidgetChanged.next(this.marutiConfig);
        });
    }

    ngOnDestroy(): void {

    }


    actionBetting($event: any, bType): any {
        this.bettingConfig.bTypeDict[bType].bettingEnabled = $event.checked;
        this.intranetService.postBetting(this.bettingConfig).then((val) => {
            this.bettingConfig = new BettingConfig(val);
        });
    }

    actionBettingSportWise($event: any, bType): any {
        this.bettingConfig.eventTypeDict[bType].bettingEnabled = $event.checked;
        this.intranetService.postBetting(this.bettingConfig).then((val) => {
            this.bettingConfig = new BettingConfig(val);
        });
    }

    actionBettingBetRadar($event: any): any {

        this.bettingConfig.betRadarEnabled = $event.checked;
        this.intranetService.postBetting(this.bettingConfig).then((val) => {
            this.bettingConfig = new BettingConfig(val);
        });
    }

    marutiActionBetting($event: MatSlideToggleChange): any {
        this.marutiConfig.Use_Maruti_Betting = $event.checked;
        this.intranetService.postMarutiConfig(this.marutiConfig).then((val) => {
            this.marutiConfig = new MarutiConfig(val);
        });
    }

    marutiActionOdds($event: MatSlideToggleChange): any {
        this.marutiConfig.Use_Maruti_Odds = $event.checked;
        this.intranetService.postMarutiConfig(this.marutiConfig).then((val) => {
            this.marutiConfig = new MarutiConfig(val);
        });
    }

    postMarutiConfig(): any {
        this.marutiConfig.Maruti_Dabba_Threshold = this.marutiConfigForm.getRawValue();

        this.intranetService.postMarutiConfig(this.marutiConfig).then((val) => {
            this.marutiConfig = new MarutiConfig(val);
            this.matSnackBar.open('Maruti Config Updated', 'OK', {
                verticalPosition: 'top',
                duration: 2000,
            });
        });
    }
}




