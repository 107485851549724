import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {query} from '@angular/animations';


@Injectable({
    providedIn: 'root'
})
export class SearchService {


    constructor(private _httpClient: HttpClient, private authorizationService: AuthenticationService) {

    }


    // tslint:disable-next-line:variable-name
    public searchBet = (pageNo: number, marketId: any, sports: any, markets: any, startDate: any, endDate: any, startTime: any, endTime: any, type: any, memberCode: any) => {
        return new Promise((resolve, reject) => {
            // tslint:disable-next-line:max-line-length
            // @ts-ignore
            // @ts-ignore
            // this._httpClient.get('https://admin.saikiseva.com/api/reports/order-list?' +
            this._httpClient.get('http://localhost:5004/api/reports/order-list?' +
                'type=' + type +
                '&page=' + pageNo +
                (marketId !== '' ? '&marketId=' + marketId : '') +
                '&from=' + startDate +
                '&to=' + endDate +
                '&sportId=' + sports +
                '&marketType=' + markets +
                (memberCode !== '' ? '&searchUserCode=' + memberCode : '') +
                '&providerId=-1', {
                    headers: this.authorizationService.getHeaders()
                }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });

    };

    public markForInvalidRevalid = (type, bets) => {
        return new Promise((resolve, reject) => {
            this._httpClient.post('http://localhost:5004/api/InvalidRevalid/addToInvalidList',{
                reason: '',
                type,
                bets
            }, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });

    }

}
