import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'scheduler',
                title: 'Scheduler\'s status',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'access_time',
                url: '/pages/schedular-status',
            },
            {
                id: 'search_event',
                title: 'Search event',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'event',
                url: '/apps/e-commerce/products',
            },
            {
                id: 'browse_bf',
                title: 'Browse Betfair',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'find_replace',
                url: '/apps/e-commerce/orders',
            },
            {
                id: 'betradar-import',
                title: 'Pending Betradar',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'compare_arrows',
                url: '/pages/betradar-import',
                badge: {
                    title: 'IMP',
                    bg: '#09d261',
                    fg: '#FFFFFF'
                }
            },
            {
                id: 'super_admins',
                title: 'Super Admin',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'account_box',
                url: '/apps/super-admins',
            },
            {
                id: 'invalid_bets',
                title: 'Invalidate Bets',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'people',
                url: '/apps/bets',
            },

            {
                id: 'notification',
                title: 'Notification',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'notifications',
                url: '/pages/notifications',
            },
            {
                id: 'betting-config',
                title: 'Betting config',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'edit_attributes',
                url: '/pages/betting-config',
            },
            {
                id: 'custom-events',
                title: 'Custom Events',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'event',
                url: '/pages/custom-events',
            },
            {
                id: 'crex-score',
                title: 'Crex Event Subscriptions',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'event',
                url: '/pages/crex-score',
            },
            // {
            //     id: 'custom-competitions',
            //     title: 'Custom Competition',
            //     translate: 'NAV.SAMPLE.TITLE',
            //     type: 'item',
            //     icon: 'stop_watch',
            //     url: '/pages/custom-competitions',
            // }
        ]
    },
    {
        id: 'markets',
        title: 'Markets',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'open_markets',
                title: 'Open Markets',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'trending_up',
                url: '/apps/open-markets',
            },
            {
                id: 'closed_markets',
                title: 'Closed Markets',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'toc',
                url: '/apps/closed-markets',
            },
            {
                id: 'settled_market',
                title: 'Last 50 markets',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'toc',
                url: '/apps/e-commerce/orders',
            },
            // {
            //     id: 'search-bet',
            //     title: 'Search Bets',
            //     translate: 'NAV.SAMPLE.TITLE',
            //     type: 'item',
            //     icon: 'trending_up',
            //     url: '/pages/search-bet',
            // },
            // {
            //     id: 'invalid-batch',
            //     title: 'Invalid Batch',
            //     translate: 'NAV.SAMPLE.TITLE',
            //     type: 'item',
            //     icon: 'trending_up',
            //     url: '/apps/chat',
            // },
            {
                id: 'settled_market_user',
                title: 'Settled Markets',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'trending_up',
                url: '/settled-markets',
            }
        ]
    },
    // {
    //     id: 'risk_management',
    //     title: 'Risk Management',
    //     translate: 'NAV.APPLICATIONS',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'net_exposure',
    //             title: 'Net Exposure',
    //             translate: 'NAV.SAMPLE.TITLE',
    //             type: 'item',
    //             icon: 'trending_up',
    //             url: '/apps/net-exposure',
    //         },
    //         {
    //             id: 'bet_ticker',
    //             title: 'Bet Ticker',
    //             translate: 'NAV.SAMPLE.TITLE',
    //             type: 'item',
    //             icon: 'toc',
    //             url: '/apps/bet-ticker',
    //         },
    //         {
    //             id: 'casino_bets',
    //             title: 'casino Bets',
    //             translate: 'NAV.SAMPLE.TITLE',
    //             type: 'item',
    //             icon: 'toc',
    //             url: '/apps/e-commerce/orders',
    //         }
    //     ]
    // }
];