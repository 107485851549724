import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomCompetitionService {

  constructor(private _http:HttpClient) { }
  url = environment.host;

  getAllCompetition() {
    return this._http.get(this.url + '/api/competitions/get-all-custom-competitions');
  }

  getCompetition(competition_id) {
    return this._http.get(this.url + '/api/competitions/get-custom-competition?competition_id=' + competition_id);
  }

  addCompetition(competition) {
  return this._http.post(this.url + '/api/competitions/add-custom-competition', competition);
  }

}
