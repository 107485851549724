import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { IntranetService } from '../../../../services/intranet.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { UtilsService } from '../../../../services/utils.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    inputCode: string = "";
    isGAEnabled: boolean = false;


    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param _intranetService
     * @param _authenticationService
     * @param _utilService
     * @param router
     * @param _matSnackBar
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _intranetService: IntranetService,
        private _authenticationService: AuthenticationService,
        private _utilService: UtilsService,
        private router: Router,
        private _matSnackBar: MatSnackBar,
        private _http: HttpClient,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.minLength(7)]],    // ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        // debugger
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.isGAEnabled) {
            this.router.navigate(['pages/schedular-status']);
        } else if (currentUser && !currentUser.isGAEnabled) {
            this.router.navigate(['/2fa']);
        }
    }

    loginUser(googleOTP?: string): void {
        this._authenticationService.login(this.loginForm.value.email, this.loginForm.value.password).pipe().subscribe((data) => {
            if (data.success) {
                if (data && data.result && data.result.isGAEnabled) {
                    this.isGAEnabled = true;
                } else {
                    this.router.navigate(['/2fa']);
                }
            } else {
                this._matSnackBar.open(data.error.message !== undefined ? data.error.message : data.error.Message, 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            }
        });
    }

    verifyGAOtp() {
        // /api/verifyGAotp
        // const URL = environment.host + '/api/agency/verifyGAotp';
        // const body = {
        // googleOTP: this.inputCode
        // }
        if (!this.inputCode) {
            this._matSnackBar.open('Please enter the code', 'OK', {
                verticalPosition: 'top',
                duration: 2000
            });
            return;
        }
        if (this.loginForm.value.email && this.loginForm.value.password && this.inputCode) {
            this._authenticationService.login(this.loginForm.value.email, this.loginForm.value.password, this.inputCode).pipe().subscribe((data) => {
                if (data.success) {
                    this.router.navigate(['pages/schedular-status']);
                } else {
                    this._matSnackBar.open(data.error.message !== undefined ? data.error.message : data.error.Message, 'OK', {
                        verticalPosition: 'top',
                        duration: 2000
                    });
                }
            });
        }
    }
}