import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomCompetitionService } from './custom-competition.service';

@Component({
  selector: 'app-custom-competition',
  templateUrl: './custom-competition.component.html',
  styleUrls: ['./custom-competition.component.scss']
})
export class CustomCompetitionComponent implements OnInit {
  displayedColumns: string[] = [
    "Id",
    "Name"
];
expandedElement: null;
columnsToDisplayWithExpand = [...this.displayedColumns, "expand"];
competitions = [];
addMode = false;

competitionForm: FormGroup;

constructor(private _customCompetitionService: CustomCompetitionService) {
    this.competitionForm = new FormGroup({
        competition_name: new FormControl("", Validators.required),
        competition_id: new FormControl(
            new Date().getTime(),
            Validators.required
        ),
    });
}

ngOnInit() {
    this._customCompetitionService.getAllCompetition().subscribe(
        (res: any) => {
            console.log(res);
            this.competitions = res.result.data;
        },
        (err: any) => {
            console.log(err);
        }
    );
}

getObjKeys(obj) {
    return Object.keys(obj);
}

add() {
  console.log(this.competitionForm);
    if (this.competitionForm.valid) {
        this.addMode = false;

        this._customCompetitionService
            .addCompetition(this.competitionForm.value)
            .subscribe(
                (res: any) => {
                    console.log(res);
                    this.competitions.push(res.result.data);
                },
                (err: any) => {
                    console.log(err);
                }
            );
    } else {
      alert('Please fill all the fields');
    }
}
}
