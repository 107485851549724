import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomEventsService {

    constructor(private _http: HttpClient) { }
    url = environment.host;

    getEvents() {
        console.log(this.url + '/api/events/getCustomEvents');
        return this._http.get(this.url + '/api/events/getCustomEvents');
    }

    searchCompetition(etmId, competitionName) {
        // console.log(this.url + '/api/competitions/searchCompetition');
        return this._http.get(this.url + '/api/competitions/searchCompetition?etmId=' + etmId + '&competitionName=' + competitionName);
    }

    addEvent(event) {
        return this._http.post(this.url + '/api/events/addCustomEvent', event);
    }

}
