import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { CustomEventsService } from './custom-events.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface event {
    event_type_id: number;
    event_type_name: string;
    id: number;
    name: string;
    open_date: string;
}

@Component({
    selector: 'app-custom-events',
    templateUrl: './custom-events.component.html',
    styleUrls: ['./custom-events.component.scss'],
})
export class CustomEventsComponent implements OnInit {
    displayedColumns: string[] = [
        'event_type_id',
        'event_type_name',
        'id',
        'name',
        'open_date',
        'Action',
    ];
    expandedElement: null;
    selectedEvent: any;
    columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
    events = [];
    competitions = [];

    addMode = false;
    selectedCompitionForm = null;

    competitionForm: FormGroup;

    constructor(private _customEventService: CustomEventsService) {
        this.competitionForm = new FormGroup({
            event_id: new FormControl('', Validators.required),
            event_name: new FormControl('', Validators.required),
            competition_name: new FormControl('', Validators.required),
            competition_id: new FormControl('', Validators.required),
            event_type_id: new FormControl('', Validators.required),
            event_type_name: new FormControl('', Validators.required),
        });
        this.selectedCompitionForm = new FormGroup({
            competition_name: new FormControl('', Validators.required),
            competition_id: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {
        this._customEventService.getEvents().subscribe(
            (res: any) => {
                console.log(res);
                this.events = res.result.data;
                const entries = [];
                res.result.data.forEach((element) => {
                    if (element.event_type_id === 99994){
                        element.event_type_id = 4;
                        element.event_type_name = 'Cricket';
                    }
                    if (element.event_type_id === 2378961){
                        element.event_type_id = 2378962;
                        element.event_type_name = 'Politics';
                    }
                    entries.push(element);
                });
                this.events = entries;
            },
            (err: any) => {
                console.log(err);
            }
        );
    }

    getObjKeys(obj) {
        return Object.keys(obj);
    }

    add() {
        console.log(this.competitionForm.value);
        if (this.competitionForm.valid) {
            if (confirm('Are you sure you want to add this event?')) {
                this.addMode = false;
                this._customEventService
                    .addEvent(this.competitionForm.value)
                    .subscribe(
                        (res: any) => {
                            console.log(res);
                            this.events.push(res.result.data);
                        },
                        (err: any) => {
                            console.log(err);
                        }
                    );
            }
        } else {
            alert('Please fill all the fields');
        }
    }

    attachEvent(element) {
        console.log(element);
        this.selectedEvent = element;
        this.competitionForm.controls['event_id'].setValue(element.id);
        this.competitionForm.controls['event_name'].setValue(element.name);
        this.competitionForm.controls['event_type_id'].setValue(
            element.event_type_id
        );
        this.competitionForm.controls['event_type_name'].setValue(
            element.event_type_name
        );
        this.addMode = true;
    }

    filterMyOptions($event) {
        console.log($event);
        console.log(this.selectedEvent);
        const competition_id =
            this.selectedCompitionForm.controls['competition_id'].value;
        if (competition_id) {
            this.competitionForm.controls['competition_id'].setValue(
                competition_id
            );
            this.competitionForm.controls['competition_name'].setValue(
                this.competitions.find((x) => x.CID == competition_id).CN
            );
        }
        if ($event.length >= 3) {
            this._customEventService
                .searchCompetition(this.selectedEvent['event_type_id'], $event)
                .subscribe(
                    (res: any) => {
                        console.log(res);
                        this.competitions = res.result;
                        // this.competitionForm.controls['competition_name'].setValue($event);
                    },
                    (err: any) => {
                        console.log(err);
                        // this.competitions = [];
                    }
                );
        } else {
            // this.competitions = [];
        }
    }
}
