import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {PendingImport} from '../pending.import';
import {IntranetService} from '../../../../services/intranet.service';
import {EventDetails} from './event.details';

@Component({
    selector: 'mapping-form-dialog',
    templateUrl: './mapping-form.component.html',
    styleUrls: ['./mapping-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MappingFormDialogComponent {
    action: string;
    pendingImport: PendingImport;
    dialogTitle: string;
    mappingForm: FormGroup;
    eventDetails: EventDetails;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     * @param _intranetService
     */
    constructor(
        public matDialogRef: MatDialogRef<MappingFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _intranetService: IntranetService
    ) {
        // Set the defaults
        this.action = _data.action;
        this.pendingImport = _data.request as PendingImport;
        this.dialogTitle = 'Mapping';
        this.mappingForm = this.createContactForm();
        if (this.pendingImport.bfEventId !== undefined && this.pendingImport.bfEventId !== '') {
            this.getMiniEventDetails(this.pendingImport.bfEventId);
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.pendingImport.id],
            name: [this.pendingImport.name],
            date: [this.pendingImport.date],
            competitionName: [this.pendingImport.competitionName],
            bfEventId: [this.pendingImport.bfEventId]
        });
    }


    getMiniEventDetails(eventId: any): any {
        this._intranetService.getCactusEventDetails(eventId).then(value => {
            this.eventDetails = value.result as EventDetails;
        });
    }


}



