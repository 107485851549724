import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {BettingConfig} from "../main/pages/betting-config/betting.config";
import {MarutiConfig} from "../main/pages/betting-config/maruti.config";

@Injectable({
    providedIn: 'root'
})
export class IntranetService {


    constructor(private _httpClient: HttpClient, private authorizationService: AuthenticationService) {

    }

    importEvent = (event) => {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authorizationService.host + '/api/events/betfair/eventToBeImported', event, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    };

    loginUser = (mobile, password) => {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authorizationService.host + '/api/appusers/auth/login', {mobile, password})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    schedularStatus = () => {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/reports/schedular-status', {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deleteScoreKey = (key) => {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/events/score/delete/' + key, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getMessages = () => {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/events/message/get', {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    saveMessage = (body) => {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authorizationService.host + '/api/events/message/set', body, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deleteMessage = (id) => {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authorizationService.host + '/api/events/message/' + id, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    siteUserStats = (days) => {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/reports/site-user-stats/?days=' + days, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getBettingConfig = () => {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/events/bettingConfig', {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                if (response.success) {
                    resolve(response.result as BettingConfig);
                }
            }, reject);
        });
    }

    postBetting = (bettingConfig: BettingConfig) => {
        return new Promise((resolve, reject) => {
            console.log(bettingConfig);
            this._httpClient.post(this.authorizationService.host + '/api/events/bettingConfig', bettingConfig, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                if (response.success) {
                    resolve(response.result as MarutiConfig);
                }
                this.getBettingConfig();
            }, reject);
        });
    }

    pendingBfImports(eventTypeId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/events/pendingBetradarImports?eventTypeId=' + (eventTypeId === undefined ? '' : eventTypeId), {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response) => {
                resolve(response);
            }, reject);
        });
    }

    getCactusEventDetails(eventId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/events/getMiniEventDetails?eventId=' + eventId, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response) => {
                resolve(response);
            }, reject);
        });
    }

    setBrEventId(id: string, isStandAlone: boolean, brEventId: any | any[]): Promise<any> {
        return new Promise<any>(((resolve, reject) => {
            this._httpClient.post(this.authorizationService.host + '/api/events/mapBREvent/?bfEventId=' + id + '&isStandAlone=' + isStandAlone, {brEventId: brEventId}, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response) => {
                resolve(response);
            }, reject);
        }));
    }

    postMarutiConfig(marutiConfig: MarutiConfig): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authorizationService.host + '/api/events/marutiConfig', marutiConfig, {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                if (response.success) {
                    resolve(response.result as MarutiConfig);
                }
            }, reject);
        });
    }

    getMarutiConfig(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authorizationService.host + '/api/events/marutiConfig', {
                headers: this.authorizationService.getHeaders()
            }).subscribe((response: any) => {
                if (response.success) {
                    resolve(response.result as MarutiConfig);
                }
            }, reject);
        });
    }
}
