import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor() {

    }

    parseResponseAndShowSnackBar(_matSnackBar, response?): void {
        if (response.success) {
            _matSnackBar.open(response.result.message, 'OK', {
                verticalPosition: 'top',
                duration: 2000,
            });
        }
    }
}


