import {Component, OnDestroy, OnInit} from '@angular/core';
import {IntranetService} from '../../../services/intranet.service';
import {BehaviorSubject} from 'rxjs';
import {UtilsService} from '../../../services/utils.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
    selector: 'app-schedular-status',
    templateUrl: './schedular-status.component.html',
    styleUrls: ['./schedular-status.component.scss']
})
export class SchedularStatusComponent implements OnInit, OnDestroy {

    widget: any;
    onWidgetChanged: BehaviorSubject<any>;
    pollers;

    constructor(
        private intranetService: IntranetService,
        private utilsService: UtilsService,
        private matSnackBar: MatSnackBar
    ) {
        this.onWidgetChanged = new BehaviorSubject<any>({});
    }

    ngOnInit(): void {
        this.widget = [];
        this.startPolling();
    }

    private startPolling(): void {
        this.pollers = setInterval(() => {
            this.getUpdate();
        }, 1000);
    }

    getUpdate = () => {
        this.intranetService.schedularStatus().then((value: any) => {
            if (value.success) {
                const array = this.processStatus(value.result);
                this.widget = array;
                this.onWidgetChanged.next(array);
            } else {
                this.utilsService.parseResponseAndShowSnackBar(this.matSnackBar, value);
            }
        });
    }

    processStatus = (arr) => {
        const array = [];

        arr.forEach((element) => {
            // const now = Date.now();
            // const then = Date.parse(element.lastUpdateTime);
            const now = moment().valueOf();
            const then = moment(element.lastUpdateTime).valueOf();
            // console.log(element.process);
            //
            // console.log(now);
            // console.log(then);
            // console.log(now - then);
            const statBool = (now - then) < (element.updateInterval + 5000);
            // console.log(statBool);
            array.push({
                process: element.process,
                lastUpdateTime: element.lastUpdateTime,
                status: statBool ? 'Active' : 'InActive',
                classes: statBool ? 'green' : 'red',
                isFaulted: element.isFaulted,
                faultyClasses: element.isFaulted ? 'red' : 'green',
                displayName: (element.displayName === undefined || element.displayName === '') ? element.process : element.displayName
            });

            // array.sort(() => {
            //
            // });
        });
        array.sort((a) => {
            if (a.status === 'InActive') {
                return -1;
            }
            if (a.isFaulted) {
                return -1;
            }

            return 1;
        });
        return array;
    }

    ngOnDestroy(): void {
        clearInterval(this.pollers);
    }

    deleteThisKey(process: string): void {
        this.intranetService.deleteScoreKey(process).then(value => {
            this.utilsService.parseResponseAndShowSnackBar(this.matSnackBar, value);
        });


    }
}
