import { Component } from "@angular/core";
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'app/services/authentication.service';
import { FancyLogsResponse, FancyLogsResult } from 'app/models/fancy-logs.interface';
import { fuseAnimations } from '@fuse/animations';


@Component({
    selector: 'settled-markets',
    templateUrl: './SettledMarkets.component.html',
    styleUrls: ['./SettledMarkets.component.scss'],
    animations: fuseAnimations
})
export class SettledMarketsComponent {

    currentUser = JSON.parse(localStorage.getItem('currentUser')) || null;
    listData = [] as FancyLogsResult[];

    constructor(private _http: HttpClient, private authorizationService: AuthenticationService) {

    }
    ngOnInit() {
        // console.log(this.currentUser);
        if (this.currentUser) {
            this.getFancyLog();
        }
    }

    getFancyLog() {
        const URL = `${environment.host}/api/agency/report/fancyLog`;
        const data = {
            marketId: null,
            userId: Number(this.currentUser.id)
        }
        this._http.post(URL, data, {
            headers: this.authorizationService.getHeaders()
        }).subscribe((res: FancyLogsResponse) => {
            if (res && res.success && res.result && res.result.length > 0) {
                // console.log("fancyLog", res.result);
                this.listData = res.result;
            }
        });
    }
}