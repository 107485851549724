import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Notifications} from './notifications.model';
import {IntranetService} from '../../../services/intranet.service';
import {UtilsService} from '../../../services/utils.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    notificationForm: FormGroup;
    notification: Notifications;
    notificationAdminForm: FormGroup;
    adminNotification: Notifications;
    requestObj: any;


    siteUsersPlayed: any;
    siteUsersLoggedIn: any;
    siteTotalUsers: any;

    constructor(
        private _formBuilder: FormBuilder,
        private intranetService: IntranetService,
        private utilsService: UtilsService,
        private matSnackBar: MatSnackBar
    ) {

        this.notification = new Notifications();
        this.notificationForm = this._formBuilder.group({
            id: [this.notification.id],
            level: [this.notification.level],
            order: [this.notification.order],
            text: [this.notification.text],
            userId: [this.notification.userId]
        });
        this.adminNotification = new Notifications();
        this.notificationAdminForm = this._formBuilder.group({
            id: [this.adminNotification.id],
            level: [this.adminNotification.level],
            order: [this.adminNotification.order],
            text: [this.adminNotification.text],
            userId: [this.adminNotification.userId]
        });
    }

    ngOnInit(): void {
        this.getMessages();
    }

    updateSiteUserStats(): void {
        this.intranetService.siteUserStats(0).then((value: any) => {
            if (value.success) {
                this.siteUsersLoggedIn = value.result.logged_in;
                this.siteUsersPlayed = value.result.placed_sauda;
                this.siteTotalUsers = value.result.total_punters;
            }
        });
    }

    getMessages = () => {
        this.intranetService.getMessages().then((value: any) => {
            if (value.success) {
                this.notification = value.result.punter[0];
                this.adminNotification = value.result.admin[0];

            }
        });
    }

    saveMessage = (type) => {

        if (type === 'punter') {
            this.requestObj = this.notificationForm.getRawValue();
            this.requestObj.type = 'punter';
        } else if (type === 'admin') {
            this.requestObj = this.notificationAdminForm.getRawValue();
            this.requestObj.type = 'admin';
        }
        if (this.requestObj.text === '') {
            this.intranetService.deleteMessage(this.requestObj.type).then((value: any) => {
                if (value.success) {
                    this.utilsService.parseResponseAndShowSnackBar(this.matSnackBar, value);
                    this.getMessages();
                }
            });
        } else {
            this.intranetService.saveMessage(this.requestObj).then((value: any) => {
                if (value.success) {
                    this.utilsService.parseResponseAndShowSnackBar(this.matSnackBar, value);
                    this.getMessages();
                }
            });
        }


    }

}
