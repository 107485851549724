export class BettingConfig {
    bTypeDict: {
        ODDS: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
        CASINO: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
        LINE: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
    };

    eventTypeDict: {
        1: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
        2: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
        4: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
        7: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
        4339: {
            bettingEnabled: boolean;
            bTypeMessage: string;
        }
    };

    betRadarEnabled: boolean;
    betRadarEnabledMessage: string;

    constructor(config?) {
        config = config || {};
        this.bTypeDict = config.bTypeDict || {
            ODDS: {
                bettingEnabled: false,
                bTypeMessage: ''
            },
            CASINO: {
                bettingEnabled: false,
                bTypeMessage: ''
            },
            LINE: {
                bettingEnabled: false,
                bTypeMessage: ''
            }
        };
        this.eventTypeDict = config.eventTypeDict || {
            1: {
                bettingEnabled: false,
                bTypeMessage: ''
            },
            2: {
                bettingEnabled: false,
                bTypeMessage: ''
            },
            4: {
                bettingEnabled: false,
                bTypeMessage: ''
            },
            7: {
                bettingEnabled: false,
                bTypeMessage: ''
            },
            4339: {
                bettingEnabled: false,
                bTypeMessage: ''
            }
        };
        this.betRadarEnabled = config.betRadarEnabled || false;
        this.betRadarEnabledMessage = config.betRadarEnabledMessage || '';

    }
}




