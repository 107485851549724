import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnableG2FA } from '@fuse/components/navigation/navigation.component';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-google2fa',
  templateUrl: './google2fa.component.html',
  styleUrls: ['./google2fa.component.scss']
})
export class Google2FAComponent implements OnInit {
  qrSource: string = "";
  setupCode: string = "";
  inputCode: string = "";
  isEnabled: boolean;

  constructor(public dialogRef: MatDialogRef<Google2FAComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EnableG2FA,
    private authorizationService: AuthenticationService,
    private _http: HttpClient,
    private _matSnackBar: MatSnackBar
  ) {
    this.qrSource = data && data.result && data.result.QRCode;
    this.setupCode = data && data.result && data.result.setUpCode;
    this.isEnabled = data && data.success;
  }
  ngOnInit() {
    // console.log(this.data, "modal data");
  }

  onClose() {
    this.dialogRef.close();
  }

  enable() {
    const url = environment.host + "/api/agency/GoogleAuthenticator/Enable";
    if (!this.inputCode || !this.data && this.data.result && this.data.result.referenceGUID) {
      this._matSnackBar.open("Please enter the code and try again", 'OK', {
        verticalPosition: 'top',
        duration: 2000
      });
      return;
    }
    const body = {
      inputCode: this.inputCode,
      refrenceGuid: this.data && this.data.result && this.data.result.referenceGUID
    }
    this._http.post(url, body, {
      headers: this.authorizationService.getHeaders()
    }).subscribe((res: any) => {
      this.dialogRef.close();
      if (res && res.success) {
        this._matSnackBar.open(res && res.success && res.status.statusDesc && res.status.statusDesc, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      } else {
        this._matSnackBar.open(res && res.status.statusDesc ? res.status.statusDesc : 'Something went wrong', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      }
    })

  }

  copyToClipboard(text: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
    this._matSnackBar.open("Copied to clipboard", 'OK', {
      verticalPosition: 'top',
      duration: 2000
    });
  }

  disable() {
    const url = environment.host + "/api/agency/GoogleAuthenticator/Disable";
    const body = {
      inputCode: this.inputCode,
    }
    this._http.post(url, body, {
      headers: this.authorizationService.getHeaders()
    }).subscribe((res: any) => {
      this.dialogRef.close();
      if (res && res.success) {
        this._matSnackBar.open(res && res.success && res.status.statusDesc && res.status.statusDesc, 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      } else {
        this._matSnackBar.open(res && res.success && res.status.statusDesc ? res.status.statusDesc : 'Something went wrong', 'OK', {
          verticalPosition: 'top',
          duration: 2000
        });
      }
    })

  }
}
