export class MarutiConfig {
    // tslint:disable-next-line:variable-name
    Maruti_Dabba_Threshold: {
        INR: number;
        PKR: number;
        HKD: number;
        USD: number;
        GBP: number;
    };
    // tslint:disable-next-line:variable-name
    Use_Maruti_Betting: boolean;
    // tslint:disable-next-line:variable-name
    Use_Maruti_Odds: boolean;

    constructor(config?) {
        config = config || {};
        this.Maruti_Dabba_Threshold = config.Maruti_Dabba_Threshold || {
            INR: 0,
            PKR: 0,
            HKD: 0,
            USD: 0,
            GBP: 0
        };
        this.Use_Maruti_Betting = config.Use_Maruti_Betting || false;
        this.Use_Maruti_Odds = config.Use_Maruti_Odds || false;
    }
}
