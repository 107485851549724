import {FuseUtils} from '@fuse/utils';

export class Notifications {
    id: string;
    level = 'SMA';
    order = 0;
    text: string;
    userId = '1';

    /**
     * Constructor
     *
     * @param notification
     */
    constructor(notification?) {
        {
            notification = notification || {};
            this.id = notification.id || FuseUtils.generateGUID();
            this.level = 'SMA';
            this.order = 0;
            this.text = notification.text || '';
            this.userId = '1';

        }
    }
}
